import { mapGetters } from 'vuex';
import wordFreq from '../workers/wordFrequency.js';
const importWorker = new Worker('../workers/importWorker.js', { type: 'module' });

export default {
	data() {
		return {
			progress: { current: 0 }
		}
	},
	computed: {
		...mapGetters({
			settings: "metadata/getSettings"
		})
	},
	mounted() {
		importWorker.onmessage = event => {
			// If finished
			if (Array.isArray(event.data)) {
				var refs = event.data;
				console.log("Worker finished!");
				setTimeout(() => {
					// Count word freq
					var words = wordFreq({
						settings: this.settings,
						progress: this.progress
					}, refs);
					// Set store
					this.$store.commit('words/setWords', words);
					// Set loading to false
					this.$root.$emit('set-loading', false);
				}, 500);
			}
			// If progress
			else {
				this.$root.$emit('set-progress', event.data);
			}
		}
		importWorker.onerror = err => {
			console.log(err);
			if (err.message) {
				alert("Error:" + err.message);
			} else {
				alert("Error:", err);
			}
			// Set loading to false
			this.$root.$emit('set-loading', false);
		}
	},
	methods: {
		importFile(file) {
			this.$root.$emit('set-progress', { stage: "parsing", progress: 0 });
			this.$root.$emit('set-loading', true);
			console.log("Sending file to worker...");
			importWorker.postMessage(file);
		},
		getFileExtention(name) {
			return "." + name.split(".").pop();
		}
	}
}