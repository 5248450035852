<template>
	<div class="mt-5">
		<b>To begin word frequency analysis, choose a library or drop it here</b>
		<b-form-file
			class="logo"
			v-model="file"
			:state="file ? true : false"
			placeholder="Choose a library or drop it here..."
			drop-placeholder="Drop file here..."
			accept=".xml, .csv, .json, .nbib, .ris, .txt, .tsv"
			@change="fileInputEvent($event.target.files[0])"
		></b-form-file>
		<b-button v-if="file" variant="success" class="mt-3" @click="fileUpload">Upload</b-button>
	</div>
</template>

<script>
import importMixin from "../mixins/importMixin"
import { BFormFile, BButton } from 'bootstrap-vue';

export default {
	name: 'FileUpload',
	mixins: [importMixin],
	components: {
		BFormFile,
		BButton
	},
	data() {
		return {
			file: null,
		}
	},
	methods: {
		fileInputEvent(file) {
			this.file = file;
		},
		fileUpload() {
			if (this.file) {
				// Read file extention
				// const fileExtention = this.getFileExtention(this.file.name);
				this.importFile(this.file);
			}
		}
	}
}
</script>

<style>
</style>
