<template>
	<b-navbar type="dark" variant="dark" sticky>
		<b-navbar-brand href="#" variant="light">
			Word Freq
			<span v-if="projectOpen">
				<span v-if="!isEditTitle">- {{ title ? title : "Untitled" }}</span>
				<span v-if="isEditTitle" style="display: inline">-
					<b-form-input
						style="display: inline; width: 250px"
						:value="title"
						placeholder="Enter project name"
						size="sm"
						@input="tempTitle = $event"
						autofocus>
					</b-form-input>
				</span>
			</span>
		</b-navbar-brand>
		<b-navbar-nav v-if="projectOpen">
			<b-button v-if="!isEditTitle" size="sm" class="mr-2" @click="isEditTitle = true" variant="primary">Edit</b-button>
			<b-button v-if="isEditTitle" size="sm" class="mr-2" @click="saveTitle" variant="success">Save</b-button>
			<b-button v-if="isEditTitle" size="sm" class="mr-2" @click="cancelEdit" variant="danger">Cancel</b-button>
		</b-navbar-nav>
		<b-navbar-nav class="ml-auto">
			<!-- <b-button class="mr-2" @click="openCite" variant="success">Cite</b-button> -->
			<b-button class="mr-2" @click="openHelp" variant="info">Help</b-button>
			<b-button class="mr-2" v-if="projectOpen" @click="$store.dispatch('resetStore')" variant="danger">Close</b-button>
		</b-navbar-nav>
	</b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';
import { BNavbar, BNavbarBrand, BNavbarNav, BButton, BFormInput } from 'bootstrap-vue';

export default {
	name: 'TheNavbar',
	props: {
		projectOpen: Boolean
	},
	data() {
		return {
			isEditTitle: false,
			tempTitle: ""
		}
	},
	components: {
		BNavbar,
		BNavbarBrand,
		BNavbarNav,
		BButton,
		BFormInput
	},
	computed: {
		...mapGetters('metadata', {
			title: 'getTitle'
		})
	},
	methods: {
		openCite() {
			// TODO: Open citation page
		},
		openHelp() {
			// Open help page
			window.open('https://sr-accelerator.com/#/help/wordfreq','_blank')
		},
		saveTitle() {
			this.$store.commit('metadata/setTitle', this.tempTitle);
			this.isEditTitle = false;
		},
		cancelEdit() {
			this.isEditTitle = false;
			this.tempTitle = this.title;
		}
	}
}
</script>