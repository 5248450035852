import Vue from 'vue'
import Vuex from 'vuex'
import metadata from './modules/metadata'
import words from './modules/words'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		metadata,
		words,
	},
	actions: {
		resetStore({ commit }) {
			commit('metadata/reset');
			commit('words/reset');
		}
	}
})